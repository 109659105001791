import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/backoffice/login',
    name: 'Login',
    component: () => import('../views/Backoffice/BackofficeLogin.vue')
  },
  {
    path: '/backoffice',
    name: 'Login',
    component: () => import('../views/Backoffice/BackofficeLogin.vue')
  },
  {
    path: '/backoffice/home',
    name: 'Home',
    component: () => import('../views/Backoffice/BackofficeHome.vue')
  },
  {
    path: '/backoffice/logout',
    name: 'Logout',
    component: () => import('../views/Backoffice/BackofficeLogout.vue')
  },
  {
    path: '/backoffice/backoffice_users',
    name: 'BackofficeUsers',
    component: () => import('../views/Backoffice/BackofficeUsers.vue')
  },
  {
    path: '/backoffice/wysiwyg',
    name: 'Wysiwyg',
    component: () => import('../views/Backoffice/BackofficeWysiwig.vue')
  },
  {
    path: '/backoffice/forgot_password',
    name: 'BackofficeForgotPassword',
    component: () => import('../views/Backoffice/BackofficeForgotPassword.vue')
  },
  {
    path : '/backoffice/backoffice_user_delete',
    name : 'BackofficeUserDelete',
    component : () => import('../views/Backoffice/BackofficeUserDelete.vue')
  },
  {
    path : '/backoffice/backoffice_user_edit',
    name : 'BackofficeUserEdit',
    component : () => import('../views/Backoffice/BackofficeUserEdit.vue')
  },
  {
    path : '/backoffice/backoffice_user_change_password',
    name : 'BackofficeUserChangePassword',
    component : () => import('../views/Backoffice/BackofficeUserChangePassword.vue')
  },
  {
    path : '/backoffice/file_uploader',
    name : 'BackofficeFileUploader',
    component : () => import('../views/Backoffice/BackofficeFileUploader.vue')
  },
  {
    path : '/backoffice/cloudinary_file_uploader',
    name : 'BackofficeCloudinaryFileUploader',
    component : () => import('../views/Backoffice/BackofficeCloudinaryFileUploader.vue')
  },
  {
    path : '/backoffice/s3_file_uploader',
    name : 'BackofficeS3FileUploader',
    component : () => import('../views/Backoffice/BackofficeS3FileUploader.vue')
  },
  {
    path : '/backoffice/payme',
    name : 'BackofficePayme',
    component : () => import('../views/Backoffice/BackofficePayme.vue')
  },
  {
    path : '/backoffice/payme_ty',
    name : 'BackofficePaymeTy',
    component : () => import('../views/Backoffice/BackofficePaymeTy.vue')
  },
  {
    path : '/backoffice/payme_order_delete',
    name : 'BackofficePaymeOrderDelete',
    component : () => import('../views/Backoffice/BackofficePaymeOrderDelete.vue')
  },
  {
    path : '/backoffice/payme_order_refund',
    name : 'BackofficePaymeOrderRefund',
    component : () => import('../views/Backoffice/BackofficePaymeOrderRefund.vue')
  },
  {
    path : '/backoffice/payme_order_ty',
    name : 'BackofficePaymeOrderTy',
    component : () => import('../views/Backoffice/BackofficePaymeOrderTy.vue')
  },
  {
    path : '/backoffice/dashbord',
    name : 'BackofficeDashbord',
    component : () => import('../views/Backoffice/BackofficeDashbord.vue')
  },
  {
    path : '/backoffice/user_report',
    name : 'BackofficeUserReport',
    component : () => import('../views/Backoffice/BackofficeUserReport.vue')
  },
  {
    path : '/backoffice/edit_user_report',
    name : 'BackofficeEditUserReport',
    component : () => import('../views/Backoffice/BackofficeEditUserReport.vue')
  },
  {
    path : '/backoffice/user_monthly_report',
    name : 'BackofficeUserMonthlyReport',
    component : () => import('../views/Backoffice/BackofficeUserMonthlyReport.vue')
  },
  {
    path : '/backoffice/emploeey_loans',
    name : 'BackofficeEmploeeyLoans',
    component : () => import('../views/Backoffice/BackofficeEmploeeyLoans.vue')
  },
  {
    path : '/backoffice/pallets',
    name : 'BackofficePallets',
    component : () => import('../views/Backoffice/BackofficePallets.vue')
  },
  {
    path : '/backoffice/new_pallets',
    name : 'BackofficeNewPallets',
    component : () => import('../views/Backoffice/BackofficeNewPallets.vue')
  },
  {
    path : '/backoffice/reports',
    name : 'BackofficeReports',
    component : () => import('../views/Backoffice/BackofficeReports.vue')
  },
  {
    path : '/backoffice/delete_report',
    name : 'BackofficeDeletetReport',
    component : () => import('../views/Backoffice/BackofficeDeletetReport.vue')
  },
  {
    path : '/backoffice/new_emploeey',
    name : 'BackofficeNewEmploeey',
    component : () => import('../views/Backoffice/BackofficeNewEmploeey.vue')
  },
  {
    path : '/backoffice/emploeey',
    name : 'BackofficeEmploeey',
    component : () => import('../views/Backoffice/BackofficeEmploeey.vue')
  },
  {
    path : '/backoffice/edit_emploeey',
    name : 'BackofficeEditEmploeey',
    component : () => import('../views/Backoffice/BackofficeEditEmploeey.vue')
  },
  {
    path : '/backoffice/edit_admin',
    name : 'BackofficeEditAdmin',
    component : () => import('../views/Backoffice/BackofficeEditAdmin.vue')
  },
  {
    path : '/backoffice/admins',
    name : 'BackofficeAdmins',
    component : () => import('../views/Backoffice/BackofficeAdmins.vue')
  },
  {
    path : '/backoffice/new_loan',
    name : 'BackofficeNewLoan',
    component : () => import('../views/Backoffice/BackofficeNewLoan.vue')
  },
  {
    path: '/',
    component : () => import('../views/Hp.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component : () => import('../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
